<template>
  <div>
    <b-sidebar
      id="app-push-sidebar"
      :visible="isAppPushUpdateSidebarShown"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="40%"
      @hidden="hideAppPushUpdateSidebar"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            APP PUSH
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <b-row>
            <b-col>
              <b-form-group
                label="성별"
                class="mr-1"
              >
                <b-form-checkbox-group
                  v-model="selectedMessageGroup.target_condition.gender"
                  :options="genderOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="SNS"
              >
                <b-form-checkbox-group
                  v-model="selectedMessageGroup.target_condition.service"
                  :options="serviceOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="연령대"
              >
                <b-form-checkbox-group
                  v-model="selectedMessageGroup.target_condition.age"
                  :options="ageOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="푸시 페이지"
              >
                <v-select
                  v-model="pushPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pushPageOptions"
                  class="invoice-filter-select"
                  placeholder="검색 항목 선택"
                  @input="clearOptionText"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="pushPage.value=='WEB'">
            <b-col>
              <b-form-group
                label="리다이렉트 URL"
              >
                <b-form-input
                  v-model="optionText"
                  placeholder="https://"
                  trim
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="pushPage.value=='CAMPAIGN'">
            <b-col>
              <b-form-group
                label="캠페인 인덱스"
              >
                <b-form-input
                  v-model="optionText"
                  trim
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="pushPage.value=='PRODUCT'">
            <b-col>
              <b-form-group
                label="상품 인덱스"
              >
                <b-form-input
                  v-model="optionText"
                  trim
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="메세지"
              >
                <!-- <b-form-input
                  v-model="pushMessage"
                  :state="pushParams.pushMessage.valid"
                  trim
                /> -->
                <b-form-textarea
                  id="textarea-state"
                  v-model="selectedMessageGroup.message"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <b-form-checkbox
                v-model="pushNow"
                name="check-button"
                switch
                inline
              >
                즉시 푸시
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="!pushNow">
            <b-col>
              <b-form-group
                label="푸시 예약"
              >
                <flat-pickr
                  v-model="selectedMessageGroup.send_at"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                variant="gradient-primary"
                class="mr-1"
                @click="updateMessageGroup"
              >
                UPDATE
              </b-button>
              <b-button
                variant="gradient-secondary"
                @click="deleteMessageGroup"
              >
                DELETE
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { defineComponent, ref, onUnmounted, onMounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  VBToggle,
  BSidebar,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'
import messageGroupStoreModule from './messageGroupStoreModule'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    VBToggle,
    BSidebar,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    flatPickr,
  },

  props: {
    isAppPushUpdateSidebarShown: Boolean,
    selectedMessageGroup: Object,
  },

  setup(props, context) {
    const pushMessage = ref('')
    const optionText = ref('')
    const pushNow = true
    const pushDate = ref(null)
    const pushPage = ref()

    const MESSAGE_GROUP_STORE_MODULE_NAME = 'message-group'
    // Register module
    if (!store.hasModule(MESSAGE_GROUP_STORE_MODULE_NAME)) {
      store.registerModule(MESSAGE_GROUP_STORE_MODULE_NAME, messageGroupStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MESSAGE_GROUP_STORE_MODULE_NAME)) {
        store.unregisterModule(MESSAGE_GROUP_STORE_MODULE_NAME)
      }
    })

    const genderOptions = [
      { text: '남성', value: 'M' },
      { text: '여성', value: 'F' },
    ]
    const serviceOptions = [
      { text: 'INSTAGRAM', value: 'INSTAGRAM' },
      { text: 'NAVER BLOG', value: 'NAVER_BLOG' },
      { text: '미보유', value: 'NOT' },
    ]
    const ageOptions = [
      { text: '10대', value: '10~19' },
      { text: '20대', value: '20~29' },
      { text: '30대', value: '30~39' },
      { text: '40대', value: '40~49' },
      { text: '50대 이상', value: '50~' },
    ]
    const pushPageOptions = [
      { label: '홈', value: 'HOME' },
      { label: '웹 링크', value: 'WEB' },
      { label: '캠페인 상세', value: 'CAMPAIGN' },
      { label: '상품 상세', value: 'PRODUCT' },
      { label: '판매 인기 상품', value: 'BEST_PRODUCTS' },
      { label: '최신 등록 상품', value: 'RECENT_PRODUCTS' },
    ]

    onMounted(() => {
      const landingPath = JSON.parse(props.selectedMessageGroup.landing_path)
      const pushType = landingPath.push_type.toUpperCase()

      pushPage.value = pushPageOptions.find(o => {
        return o.value === pushType
      })
      console.log(landingPath)
      switch (pushType) {
        case 'WEB':
          optionText.value = landingPath.url
          break;
        case 'CAMPAIGN':
          optionText.value = landingPath.param[0].campaign_index
          break;
        case 'PRODUCT':
          optionText.value = landingPath.param[0].tagby_product_index
          break;
        default:
          optionText.value = ''
      }
    })

    const hideAppPushUpdateSidebar = () => {
      context.emit('onHiddenSidebar')
    }

    const clearOptionText = () => {
      optionText.value = ''
    }

    const toast = useToast()
    const updateMessageGroup = async () => {
      if (props.selectedMessageGroup.state === 'SENT') {
        toast({
          component: ToastificationContent,
          props: {
            title: '이미 발송된 푸시입니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }
      try {
        await store.dispatch(
          `${MESSAGE_GROUP_STORE_MODULE_NAME}/updateAppPushInf`,
          {
            idx: props.selectedMessageGroup.idx,
            target_condition: props.selectedMessageGroup.target_condition,
            push_params: {
              message: props.selectedMessageGroup.message,
              send_at: moment(props.selectedMessageGroup.send_at).utc().format('YYYY-MM-DD HH:mm:ss'),
              push_page: pushPage.value.value,
              option_text: optionText.value,
            },
          },
        )
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'primary',
          },
        })
      } catch (e) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Cannot Delete App push',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const deleteMessageGroup = async () => {
      if (props.selectedMessageGroup.state === 'SENT') {
        toast({
          component: ToastificationContent,
          props: {
            title: '이미 발송된 푸시입니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }
      try {
        await store.dispatch(
          `${MESSAGE_GROUP_STORE_MODULE_NAME}/deleteAppPushInf`,
          {
            idx: props.selectedMessageGroup.idx,
          }
        )
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'BellIcon',
            variant: 'primary',
          },
        })
      } catch (e) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Cannot Delete App push',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    return {
      pushMessage,
      optionText,
      pushNow,
      pushDate,
      pushPage,

      // options
      genderOptions,
      serviceOptions,
      ageOptions,
      pushPageOptions,

      clearOptionText,
      hideAppPushUpdateSidebar,
      updateMessageGroup,
      deleteMessageGroup,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>