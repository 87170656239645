<template>
  <div>
    <b-card
      no-body
    >
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="messageGroups"
        :fields="tableColumns"
        primary-key="idx"
        show-empty
        empty-text="No matching records found"
        small
        responsive
      >
        <template #cell(admin)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'member-inf-detail', params: { idx: data.item.idx } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.admin.name }}
            </b-link>
            <small class="text-muted">{{ data.item.admin.email }}</small>
          </b-media>
        </template>

        <template #cell(details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            button
            button-variant="outline-primary"
            size="sm"
            @change="row.toggleDetails"
          >
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col>
                메세지: {{ row.item.message }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                발송조건: {{ row.item.target_condition }}
              </b-col>
            </b-row>
          </b-card>
        </template>

        <template #cell(channel)="data">
          <span>
            {{ data.value }}
          </span>
        </template>

        <template #cell(landing_path)="data">
          <div style="max-width: 300px;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(target_count)="data">
          <div style="max-width: 90px;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(state)="data">
          <div style="max-width: 90px;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(send_at)="data">
          <div style="max-width: 90px;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(created_at)="data">
          <div style="max-width: 90px;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(update)="data">
          <div style="max-width: 90px;">
            <b-button
              variant="outline-primary"
              @click="$emit('showAppPushUpdateSidebar', data.item)"
              v-text="'수정'"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :busy="isBusy"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
  },
  props: {
    messageGroups: {
      type: Array,
      default: null,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'idx' },
        { key: 'admin', label: '어드민' },
        { key: 'details', label: '상세내용' },
        { key: 'channel', label: '채널' },
        { key: 'landing_path', label: '푸시 페이지', formatter: val => this.landing_formatter(val) },
        { key: 'target_count', label: '인원' },
        { key: 'success_count', label: '성공' },
        { key: 'fail_count', label: '실패' },
        { key: 'state', label: '상태' },
        { key: 'send_at', label: '전송일시', formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` },
        { key: 'created_at', label: '요청일시', formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` },
        { key: 'update', label: '수정' },
      ],
      currentPage: 1,
      perPage: 20,
    }
  },
  computed: {
    dataMeta: {
      get() {
        const localItemsCount = this.messageGroups?.length ?? 0
        return {
          from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
          to: this.perPage * (this.currentPage - 1) + localItemsCount,
          of: this.totalRows,
        }
      },
    },
  },
  watch: {
    currentPage(value) {
      this.$emit('set-page', value)
    },
    perPage(value) {
      this.$emit('set-size', value)
    },
  },
  methods: {
    landing_formatter(val) {
      if (val == null) return ''
      const landingPath = JSON.parse(val)
      const pushType = landingPath.push_type
      if (pushType === 'home') {
        return '홈'
      }
      if (pushType === 'web') {
        const { url } = landingPath
        return `웹 링크(${url})`
      }
      if (pushType === 'campaign') {
        const { param } = landingPath
        const campaignIndex = param[0].campaign_index
        return `캠페인 상세(${campaignIndex})`
      }
      return ''
    },
  },

}
</script>
