<template>
  <div>
    <b-sidebar
      id="sms-sidebar"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @hidden="onHiddenSMS"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            SMS
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <b-row class="mb-1 text-success">
            <b-col>
              <span class="mr-1">선정인원</span>
              <span>{{ influencerCount }}명</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="성별"
                class="mr-1"
              >
                <b-form-checkbox-group
                  v-model="searchParams.gender"
                  :options="genderOptions"
                  name="buttons-1"
                  @input="countInfluencer"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="SNS"
              >
                <b-form-checkbox-group
                  v-model="searchParams.service"
                  :options="serviceOptions"
                  name="buttons-1"
                  @input="countInfluencer"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="메세지"
              >
                <b-form-input
                  v-model="pushMessage"
                  :state="pushParams.pushMessage.valid"
                  trim
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-1">
            <b-col>
              <b-form-checkbox
                v-model="pushParams.pushNow"
                name="check-button"
                switch
                inline
              >
                즉시 푸시
              </b-form-checkbox>
            </b-col>
          </b-row> -->
          <b-row v-if="!pushParams.pushNow">
            <b-col>
              <b-form-group
                label="푸시 예약"
              >
                <flat-pickr
                  v-model="pushParams.pushDate.value"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                variant="gradient-primary"
                class="mr-1"
                @click="sms"
              >
                SEND
              </b-button>
              <b-button
                variant="gradient-secondary"
                @click="onHiddenSMS"
              >
                CANCEL
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  VBToggle,
  BSidebar,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import messageGroupStoreModule from './messageGroupStoreModule'

const MESSAGE_GROUP_STORE_MODULE_NAME = 'message-group'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    flatPickr,
    BSidebar,
    BFormInvalidFeedback,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      genderOptions: [
        { text: '남성', value: 'M' },
        { text: '여성', value: 'F' },
      ],
      serviceOptions: [
        { text: 'INSTA BASIC', value: 'INSTA_BASIC' },
        { text: 'INSTA BIZ', value: 'INSTA_BIZ' },
        { text: 'NAVER BLOG', value: 'NAVER_BLOG' },
      ],
      searchParams: {
        gender: [],
        service: [],
      },
      pushParams: {
        pushMessage: {
          value: null,
          valid: false,
        },
        pushNow: true,
        pushDate: {
          value: null,
          valid: false,
        },
      },
      influencerCount: 0,
    }
  },
  computed: {
    pushMessage: {
      get() {
        return this.pushParams.pushMessage.value
      },

      set(item) {
        this.pushParams.pushMessage.value = item
        this.pushParams.pushMessage.valid = !!item
      },
    },
  },
  mounted() {
    if (!store.hasModule(MESSAGE_GROUP_STORE_MODULE_NAME)) {
      store.registerModule(MESSAGE_GROUP_STORE_MODULE_NAME, messageGroupStoreModule)
    }

    this.countInfluencer()
  },
  methods: {
    countInfluencer() {
      store
        .dispatch(`${MESSAGE_GROUP_STORE_MODULE_NAME}/countInfluencer`,
          {
            ...this.searchParams,
            channel: 'SMS',
          })
        .then(response => {
          const { data } = response.data
          this.influencerCount = data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching influencer count',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sms() {
      const data = {
        push_params: {
          push_message: this.pushParams.pushMessage.value,
          push_now: this.pushParams.pushNow,
          push_date: moment(this.pushParams.pushDate.value).utc().format('YYYY-MM-DD HH:mm'),
        },
        search_params: this.searchParams,
      }
      store
        .dispatch(`${MESSAGE_GROUP_STORE_MODULE_NAME}/smsInf`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'SMS 전송에 성공했습니다.',
              variant: 'primary',
            },
          })
          this.$emit('fetch')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fail',
              icon: 'BellIcon',
              text: 'SMS 전송에 실패했습니다.',
              variant: 'danger',
            },
          })
        })
    },
    clearPushParams() {
      this.pushMessage = null
      this.pushParams.pushNow = true
      this.pushParams.pushDate.value = null
    },
    onHiddenSMS() {
      this.searchParams.gender = []
      this.searchParams.service = []
      this.clearPushParams()
      this.$emit('on-hidden-sidebar')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
