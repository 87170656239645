<template>
  <div>
    <message-group-list-filters
      :search-params="searchParams"
      @turn-on-app-push-sidebar="turnOnAppPushSidebar"
      @turn-on-sms-sidebar="turnOnSmsSidebar"
      @search="fetchMessageGroupList"
    />
    <app-push-sidebar
      :show-sidebar="showAppPushSidebar"
      @on-hidden-sidebar="onHiddenAppPushSidebar"
      @fetch="fetchMessageGroupList"
    />
    <sms-sidebar
      :show-sidebar="showSmsSidebar"
      @on-hidden-sidebar="onHiddenSmsSidebar"
      @fetch="fetchMessageGroupList"
    />
    <app-push-update-sidebar
      v-if="selectedMessageGroup"
      :selectedMessageGroup="selectedMessageGroup"
      :isAppPushUpdateSidebarShown="isAppPushUpdateSidebarShown"
      @onHiddenSidebar="hideAppPushUpdateSidebar"
    />
    <message-group-list-table
      :message-groups="messageGroups"
      :total-rows="totalCount"
      :is-busy="isBusy"
      @set-page="setPage"
      @set-size="setSize"
      @showAppPushUpdateSidebar="showAppPushUpdateSidebar"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MessageGroupListFilters from './MessageGroupListFilters.vue'
import AppPushSidebar from './AppPushSidebar.vue'
import SmsSidebar from './SmsSidebar.vue'
import AppPushUpdateSidebar from './AppPushUpdateSidebar.vue'
import MessageGroupListTable from './MessageGroupListTable.vue'
import messageGroupStoreModule from './messageGroupStoreModule'

import store from '@/store'
import router from '@/router'

const MESSAGE_GROUP_STORE_MODULE_NAME = 'message-group'

export default {
  components: {
    MessageGroupListFilters,
    AppPushSidebar,
    SmsSidebar,
    AppPushUpdateSidebar,
    MessageGroupListTable,
  },
  data() {
    return {
      searchParams: {
        page: 1,
        size: 20,
        total: 0,
        admin_name: [],
        created_at: [],
      },
      showAppPushSidebar: false,
      showSmsSidebar: false,
      isAppPushUpdateSidebarShown: false,
      selectedMessageGroup: null,
      messageGroups: [],
      totalCount: 0,
      isBusy: false,
    }
  },
  mounted() {
    if (!store.hasModule(MESSAGE_GROUP_STORE_MODULE_NAME)) {
      store.registerModule(MESSAGE_GROUP_STORE_MODULE_NAME, messageGroupStoreModule)
    }

    this.searchParams = {
      ...this.searchParams,
      ...router.history.current.query,
    }
    this.fetchMessageGroupList()
  },
  unmounted() {
    if (store.hasModule(MESSAGE_GROUP_STORE_MODULE_NAME)) {
      store.unregisterModule(MESSAGE_GROUP_STORE_MODULE_NAME)
    }
  },
  methods: {
    setPage(value) {
      this.searchParams.page = value
      this.fetchMessageGroupList()
    },
    setSize(value) {
      this.searchParams.size = value
      this.fetchMessageGroupList()
    },
    turnOnAppPushSidebar() {
      this.showAppPushSidebar = true
    },
    turnOnSmsSidebar() {
      this.showSmsSidebar = true
    },
    showAppPushUpdateSidebar(messageGroup) {
      this.isAppPushUpdateSidebarShown = true
      this.selectedMessageGroup = messageGroup
    },
    hideAppPushUpdateSidebar() {
      this.isAppPushUpdateSidebarShown = false
      this.selectedMessageGroup = null
    },
    onHiddenAppPushSidebar() {
      this.showAppPushSidebar = false
    },
    onHiddenSmsSidebar() {
      this.showSmsSidebar = false
    },
    datetimePreprocessing(datetime) {
      return datetime
        .map(o => o
          .split(' to ')
          .map(date => moment(new Date(date).setHours(0))
            .utc()
            .format('YYYY-MM-DD HH'))
          .join(' to '))
    },
    fetchMessageGroupList() {
      this.isBusy = true
      store
        .dispatch(`${MESSAGE_GROUP_STORE_MODULE_NAME}/fetchMessageGroupList`, {
          ...this.searchParams,
          created_at: this.datetimePreprocessing(this.searchParams.created_at),
        })
        .then(response => {
          const { page_info, data } = response.data
          this.messageGroups = data
          this.totalCount = page_info.total_count
          this.searchParams.total = page_info.total_count
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isBusy = false
          router.replace({
            query: this.searchParams,
          }).catch(() => {})
        })
    },
  },
}
</script>
