import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMessageGroupList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // 인플루언서 앱 푸시
    appPushInf(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/app-push/`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateAppPushInf(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/app-push/${data.idx}/`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteAppPushInf(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/app-push/${data.idx}/?mode=DELETE`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    smsInf(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/sms/`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    countInfluencer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/count-influencer/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCountInfluencer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/message-group/get-count-influencer/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
