<template>
  <div>
    <b-sidebar
      id="app-push-sidebar"
      :visible="showSidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      width="40%"
      @hidden="onHiddenAppPush"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            APP PUSH
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="p-1">
          <b-row class="mb-1 text-success">
            <b-col>
              <span class="mr-1">선정인원</span>
              <span>{{ influencerCount }}명</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="성별"
                class="mr-1"
              >
                <b-form-checkbox-group
                  v-model="gender"
                  :options="genderOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="SNS"
              >
                <b-form-checkbox-group
                  v-model="service"
                  :options="serviceOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="연령대"
              >
                <b-form-checkbox-group
                  v-model="age"
                  :options="ageOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="지역"
              >
                <b-form-checkbox-group
                  v-model="region"
                  :options="regionOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="팔로워"
              >
                <b-form-checkbox-group
                  v-model="follower"
                  :options="followerOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="일 평균 방문자"
              >
                <b-form-checkbox-group
                  v-model="avgReach"
                  :options="avgReachOptions"
                  name="buttons-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="푸시 페이지"
              >
                <v-select
                  v-model="pushPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pushPageOptions"
                  class="invoice-filter-select"
                  placeholder="검색 항목 선택"
                  @input="clearOptionText"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="pushParams.pushPage.value=='WEB'">
            <b-col>
              <b-form-group
                label="리다이렉트 URL"
              >
                <b-form-input
                  v-model="optionText"
                  :state="pushParams.optionText.valid"
                  placeholder="https://"
                  trim
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="pushParams.pushPage.value=='CAMPAIGN'">
            <b-col>
              <b-form-group
                label="캠페인 인덱스"
              >
                <b-form-input
                  v-model="optionText"
                  :state="pushParams.optionText.valid"
                  trim
                  placeholder="ex) 432"
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="pushParams.pushPage.value=='PRODUCT'">
            <b-col>
              <b-form-group
                label="상품 인덱스"
              >
                <b-form-input
                  v-model="optionText"
                  :state="pushParams.optionText.valid"
                  trim
                  placeholder="ex) 432"
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="메세지"
              >
                <b-form-textarea
                  id="textarea-state"
                  v-model="pushMessage"
                  :state="pushParams.pushMessage.valid"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  필수 입력값 입니다.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <b-form-checkbox
                v-model="pushParams.pushNow"
                name="check-button"
                :disabled="!isValidTimeAtNow"
                switch
                inline
              >
                즉시 푸시
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="!pushParams.pushNow">
            <b-col>
              <b-form-group
                label="푸시 예약"
              >
                <flat-pickr
                  v-model="pushDate"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                  :class="pushParams.pushDate.valid ? 'flatpickr-input': 'my-invalid-pickr'"
                />
                <small
                  v-show="!pushParams.pushDate.valid"
                  class="my-invalid-font"
                >
                  9:30 ~ 20:30 까지만 설정이 가능합니다.
                </small>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                variant="outline-primary"
                class="mr-1"
                :disabled="!isValid"
                @click="appPush"
              >
                확인
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="onHiddenAppPush"
              >
                취소
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  VBToggle,
  BSidebar,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import messageGroupStoreModule from './messageGroupStoreModule'

const MESSAGE_GROUP_STORE_MODULE_NAME = 'message-group'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    BSidebar,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      genderOptions: [
        { text: '전체', value: 'ALL' },
        { text: '남성', value: 'M' },
        { text: '여성', value: 'F' },
      ],
      serviceOptions: [
        { text: '전체', value: 'ALL' },
        { text: 'INSTAGRAM', value: 'INSTAGRAM' },
        { text: 'NAVER BLOG', value: 'NAVER_BLOG' },
        { text: '연결끊김', value: 'INACTIVE' },
        { text: '연결이력없음', value: 'NOT' },
      ],
      ageOptions: [
        { text: '전체', value: 'ALL' },
        { text: '10대', value: '10~19' },
        { text: '20대', value: '20~29' },
        { text: '30대', value: '30~39' },
        { text: '40대', value: '40~49' },
        { text: '50대 이상', value: '50~' },
      ],
      regionOptions: [
        { text: '전체', value: 'ALL' },
        { text: '인천', value: '인천' },
        { text: '경기', value: '경기' },
        { text: '서울', value: '서울' },
        { text: '대구', value: '대구' },
        { text: '부산', value: '부산' },
        { text: '울산', value: '울산' },
        { text: '경상', value: '경상' },
        { text: '대전', value: '대전' },
        { text: '충청', value: '충청' },
        { text: '광주', value: '광주' },
        { text: '강원', value: '강원' },
        { text: '전라', value: '전라' },
        { text: '세종', value: '세종' },
        { text: '제주', value: '제주' },
        { text: '배송지 없음', value: 'NOT' },
      ],
      followerOptions: [
        { text: '전체', value: 'ALL' },
        { text: '~1만', value: '~10000' },
        { text: '1만~5만', value: '10000~50000' },
        { text: '5만~10만', value: '50000~100000' },
        { text: '10만 이상', value: '100000~' },
      ],
      avgReachOptions: [
        { text: '전체', value: 'ALL' },
        { text: '~1천', value: '~1000' },
        { text: '1천~5천', value: '1000~5000' },
        { text: '5천~1만', value: '5000~10000' },
        { text: '1만~5만', value: '10000~50000' },
        { text: '5만 이상', value: '50000~' },
      ],
      pushPageOptions: [
        { label: '홈', value: 'HOME' },
        { label: '웹 링크', value: 'WEB' },
        { label: '캠페인 상세', value: 'CAMPAIGN' },
        { label: '캠페인 목록', value: 'CAMPAIGN_LIST' },
        { label: '상품 상세', value: 'PRODUCT' },
        { label: '판매 인기 상품', value: 'BEST_PRODUCTS' },
        { label: '최신 등록 상품', value: 'RECENT_PRODUCTS' },
      ],
      gender: ['ALL'],
      service: ['ALL'],
      age: ['ALL'],
      region: ['ALL'],
      follower: ['ALL'],
      avgReach: ['ALL'],
      pushParams: {
        pushPage: {
          value: 'HOME',
          valid: true,
        },
        optionText: {
          value: null,
          valid: false,
        },
        pushMessage: {
          value: null,
          valid: false,
        },
        pushNow: this.isValidTimeAtNow,
        pushDate: {
          value: null,
          valid: false,
        },
      },
      influencerCount: 0,
    }
  },
  computed: {
    isValidTimeAtNow: {
      get() {
        const availableStartTime = moment().set({ hour: 9, minute: 30 })
        const availableEndTime = moment().set({ hour: 20, minute: 30 })
        const now = moment()
        return now.isSameOrAfter(availableStartTime) && now.isSameOrBefore(availableEndTime)
      },
    },
    pushMessage: {
      get() {
        return this.pushParams.pushMessage.value
      },

      set(item) {
        this.pushParams.pushMessage.value = item
        this.pushParams.pushMessage.valid = !!item
      },
    },
    optionText: {
      get() {
        return this.pushParams.optionText.value
      },

      set(item) {
        this.pushParams.optionText.value = item
        this.pushParams.optionText.valid = !!item
      },
    },
    pushPage: {
      get() {
        return this.pushPageOptions.find(o => o.value === this.pushParams.pushPage.value)
      },

      set(item) {
        this.pushParams.pushPage.value = item.value
        this.pushParams.pushPage.valid = item.value != null
      },
    },
    pushDate: {
      get() {
        return this.pushParams.pushDate.value
      },

      set(value) {
        this.pushParams.pushDate.value = value
        const availableStartTime = moment(value).set({ hour: 9, minute: 30 })
        const availableEndTime = moment(value).set({ hour: 20, minute: 30 })
        const inputedTime = moment(value)
        this.pushParams.pushDate.valid = inputedTime.isSameOrAfter(availableStartTime) && inputedTime.isSameOrBefore(availableEndTime)
      },
    },
    searchParams: {
      get() {
        const gender = this.gender.length === 1 && this.gender[0] === 'ALL' ? this.genderOptions.filter(e => e.value !== 'ALL').map(e => e.value) : this.gender
        const service = this.service.length === 1 && this.service[0] === 'ALL' ? this.serviceOptions.filter(e => e.value !== 'ALL').map(e => e.value) : this.service
        const age = this.age.length === 1 && this.age[0] === 'ALL' ? this.ageOptions.filter(e => e.value !== 'ALL').map(e => e.value) : this.age
        const region = this.region.length === 1 && this.region[0] === 'ALL' ? this.regionOptions.filter(e => e.value !== 'ALL').map(e => e.value) : this.region
        const follower = this.follower.length === 1 && this.follower[0] === 'ALL' ? this.followerOptions.filter(e => e.value !== 'ALL').map(e => e.value) : this.follower
        const avgReach = this.avgReach.length === 1 && this.avgReach[0] === 'ALL' ? this.avgReachOptions.filter(e => e.value !== 'ALL').map(e => e.value) : this.avgReach
        return {
          gender,
          service,
          age,
          region,
          follower,
          avg_reach: avgReach,
        }
      },
    },
    isValid: {
      get() {
        return this.pushParams.pushPage.valid && this.pushParams.pushMessage.valid && (this.pushParams.pushNow || this.pushParams.pushDate.valid)
      },
    },
  },
  watch: {
    gender(newValue) {
      const { length } = newValue
      if (length === 0) {
        this.gender = ['ALL']
      } else if (length >= 2) {
        if (newValue[0] === 'ALL') {
          this.gender = newValue.splice(1)
        } else if (newValue[length - 1] === 'ALL') {
          this.gender = ['ALL']
        } else {
          this.countInfluencer()
        }
      } else {
        this.countInfluencer()
      }
    },
    service(newValue) {
      const { length } = newValue
      if (length === 0) {
        this.service = ['ALL']
      } else if (length >= 2) {
        if (newValue[0] === 'ALL') {
          this.service = newValue.splice(1)
        } else if (newValue[length - 1] === 'ALL') {
          this.service = ['ALL']
        } else {
          this.countInfluencer()
        }
      } else {
        this.countInfluencer()
      }
    },
    age(newValue) {
      const { length } = newValue
      if (length === 0) {
        this.age = ['ALL']
      } else if (length >= 2) {
        if (newValue[0] === 'ALL') {
          this.age = newValue.splice(1)
        } else if (newValue[length - 1] === 'ALL') {
          this.age = ['ALL']
        } else {
          this.countInfluencer()
        }
      } else {
        this.countInfluencer()
      }
    },
    region(newValue) {
      const { length } = newValue
      if (length === 0) {
        this.region = ['ALL']
      } else if (length >= 2) {
        if (newValue[0] === 'ALL') {
          this.region = newValue.splice(1)
        } else if (newValue[length - 1] === 'ALL') {
          this.region = ['ALL']
        } else {
          this.countInfluencer()
        }
      } else {
        this.countInfluencer()
      }
    },
    follower(newValue) {
      const { length } = newValue
      if (length === 0) {
        this.follower = ['ALL']
      } else if (length >= 2) {
        if (newValue[0] === 'ALL') {
          this.follower = newValue.splice(1)
        } else if (newValue[length - 1] === 'ALL') {
          this.follower = ['ALL']
        } else {
          this.countInfluencer()
        }
      } else {
        this.countInfluencer()
      }
    },
    avgReach(newValue) {
      const { length } = newValue
      if (length === 0) {
        this.avgReach = ['ALL']
      } else if (length >= 2) {
        if (newValue[0] === 'ALL') {
          this.avgReach = newValue.splice(1)
        } else if (newValue[length - 1] === 'ALL') {
          this.avgReach = ['ALL']
        } else {
          this.countInfluencer()
        }
      } else {
        this.countInfluencer()
      }
    },
  },
  mounted() {
    if (!store.hasModule(MESSAGE_GROUP_STORE_MODULE_NAME)) {
      store.registerModule(MESSAGE_GROUP_STORE_MODULE_NAME, messageGroupStoreModule)
    }

    this.countInfluencer()
  },
  methods: {
    countInfluencer() {
      store
        .dispatch(`${MESSAGE_GROUP_STORE_MODULE_NAME}/getCountInfluencer`,
          {
            ...this.searchParams,
            channel: 'PUSH',
          })
        .then(response => {
          const { data } = response.data
          this.influencerCount = data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching influencer count',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    appPush() {
      console.log(this.searchParams)
      const data = {
        push_params: {
          push_page: this.pushParams.pushPage.value,
          option_text: this.pushParams.optionText.value,
          push_message: this.pushParams.pushMessage.value,
          push_date: (this.pushParams.pushNow ? moment().utc() : moment(this.pushParams.pushDate.value).utc()).format('YYYY-MM-DD HH:mm:ss'),
        },
        search_params: this.searchParams,
      }
      store
        .dispatch(`${MESSAGE_GROUP_STORE_MODULE_NAME}/appPushInf`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: '앱푸시에 성공했습니다.',
              variant: 'primary',
            },
          })
          this.$emit('fetch')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fail',
              icon: 'BellIcon',
              text: '앱푸시에 실패했습니다.',
              variant: 'danger',
            },
          })
        })
    },
    clearPushParams() {
      this.pushMessage = null
      this.optionText = null
      this.pushDate = null
      this.pushParams.pushNow = this.isValidTimeAtNow
    },
    clearOptionText() {
      this.optionText = null
    },
    onHiddenAppPush() {
      this.gender = []
      this.service = []
      this.age = []
      this.clearPushParams()
      this.$emit('on-hidden-sidebar')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.my-invalid-pickr {
  border-color: var(--danger);
}
.my-invalid-font {
  color: var(--danger);
}
</style>
