<template>
  <div>
    <b-card class="p-100">
      <b-card-header>
        <h2 class="pb-50">
          검색 필터
        </h2>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            xl="6"
          >
            <b-form-group
              label="카테고리별 검색"
            >
              <b-row>
                <b-col
                  cols="12"
                  xl="4"
                >
                  <v-select
                    v-model="textCategorySelect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="textCategoryOptions"
                    class="invoice-filter-select"
                    placeholder="검색 항목 선택"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col>
                  <b-input-group>
                    <flat-pickr
                      v-if="showDateInput"
                      v-model="textInput"
                      class="form-control"
                      :config="{ mode: 'range'}"
                    />
                    <b-form-input
                      v-else
                      v-model="textInput"
                      class="d-inline-block"
                      placeholder="검색 텍스트 입력"
                      @keyup.enter="addTag"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="addTag"
                      >
                        Add
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>

                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-tags
              v-model="tagParams"
              input-id="tags-basic"
              class="mb-2"
              :tag-validator="() => false"
              invalid-tag-text="이곳에 직접 입력할 수 없습니다"
              placeholder="위에서 입력한 값을 보여줍니다"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex">
            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="search"
            >
              검색
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="$emit('turn-on-app-push-sidebar')"
            >
              App Push
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$emit('turn-on-sms-sidebar')"
            >
              SMS
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    searchParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      textCategoryOptions: [
        { label: '어드민 이름', value: 'ADMIN_NAME' },
        { label: '요청일시', value: 'CREATED_AT' },
      ],
      textCategorySelect: { label: '어드민 이름', value: 'ADMIN_NAME' },
      textInput: '',
    }
  },
  computed: {
    showDateInput: {
      get() {
        return this.textCategorySelect?.value === 'CREATED_AT'
      },
    },
    tagParams: {
      get() {
        const results = []
        const adminName = this.searchParams.admin_name.map(o => `어드민_이름:${o}`)
        const createdAt = this.searchParams.created_at.map(o => `요청일시:${o}`)
        return results
          .concat(adminName)
          .concat(createdAt)
      },

      set(items) {
        Object.assign(this.searchParams, {
          admin_name: [],
          created_at: [],
        })
        items.forEach(item => {
          const [key, value] = item.split(':')
          if (key === '어드민_이름') {
            this.searchParams.admin_name.push(value)
          } else if (key === '요청일시') {
            this.searchParams.created_at.push(value)
          }
        })
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', e => {
      if (e.code === 'Enter' && (e.metaKey || e.ctrlKey)) {
        this.search()
      }
    })
  },
  methods: {
    test() {
      console.log(this.searchParams)
    },
    addTag() {
      const key = this.textCategorySelect?.label
      const value = this.textInput
      if (value != null && value !== '') {
        if (key === '어드민 이름') {
          this.searchParams.admin_name.push(value)
        } else if (key === '요청일시') {
          this.searchParams.created_at.push(value)
        }
      }
      this.textInput = null
    },
    search() {
      this.$emit('search', this.searchParams)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
